<template>
  <div class="flex flex-wrap justify-center my-10">
    <div
      v-for="(i, index) in elements"
      :key="index"
      class="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 p-4"
    >
      <div class="w-full">
        <img
          class="hidden md:block object-cover md:object-contain w-full"
          :src="i.image"
          height="342px;"
        />
        <img
          class="block md:hidden object-cover md:object-contain w-full"
          :src="i.image_mobile"
          height="342px;"
        />
        <div class="w-full justify-center flex">
          <div class="flex flex-col text-center mt-4 w-10/12">
            <h2 class="my-2 font-bold">{{ i.title }}</h2>
            <span class="my-2">{{ i.description }}</span>
            <a
              class="hover:underline mb-2 mt-10 font-bold cursor-pointer"
              :class="{ 'text-primary3': clickStates[index] }"
              @click="redirectToURL(index, i.cta_link)"
              >{{ i.cta_title }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["elements"],
  data() {
    return {
      imageData: [],
      clickStates: [],
    };
  },
  created() {
    this.clickStates = Array(this.elements.length).fill(false);
  },
  methods: {
    redirectToURL(index, url) {
      this.$set(this.clickStates, index, true);
      if (typeof window !== "undefined") {
        window.open(this.$replaceUrlTemplate(url, this.$getUserType()));
      }
    },
  },
};
</script>
